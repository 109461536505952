// 验证卡号
function isCardNo(value: string): boolean {
  // accept only digits and dashes
  if (/[^0-9-]+/.test(value)) return false
  const lengthWhiteList = [16, 15, 14, 13]
  const lIndex = lengthWhiteList.findIndex((l) => value.length === l)
  if (lIndex === -1) return false
  const startLetter = value.substring(0, 1)
  const startLetterWhiteList = ['1', '2', '3', '4', '5', '6']
  const sIndex = startLetterWhiteList.findIndex((s) => startLetter === s)
  if (sIndex === -1) return false
  // var nCheck = 0, nDigit = 0, bEven = false;
  // value = value.replace(/\D/g, "");
  // for (var n = value.length - 1; n >= 0; n--) {
  //     var cDigit = value.charAt(n);
  //     var nDigit = parseInt(cDigit, 10);
  //     if (bEven) {
  //         if ((nDigit *= 2) > 9)
  //             nDigit -= 9;
  //     }
  //     nCheck += nDigit;
  //     bEven = !bEven;
  // }
  return true
}

function isEmail(value: string): boolean {
  /* eslint-disable */
  return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i.test(value)// @ts-ignore
  /* eslint-enable */
}

// 识别卡号对应卡的类型
function getCardType(cardNo: string): string {
  let cardType = ''
  const firstNum = cardNo.substr(0, 1)
  const FSNum = cardNo.substr(0, 2)
  const FourNum = cardNo.substr(0, 4)
  if (FSNum === '35' || FourNum === '2131' || FourNum === '1800') {
    cardType = 'JCB'
  } else if (FSNum === '34' || FSNum === '37') {
    cardType = 'AE'
  } else if (firstNum === '4') {
    cardType = 'VISA'
  } else if (firstNum === '5') {
    cardType = 'MASTER'
  }
  return cardType
}

export { isCardNo, isEmail, getCardType }
